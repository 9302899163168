import { React, useState } from 'react'
import { useLocation } from 'react-router-dom';
import MetaTags from '../components/MetaTags';

function Home() {

    const [fullname, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [response, setResponse] = useState("");
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let origin = location.pathname.length > 1 ? location.pathname.replace('/', '').charAt(0).toUpperCase() + location.pathname.slice(2) : 'Home';

            let res = await fetch("https://hook.eu2.make.com/iauzb7zypebiqon5rdbslwtnmglbbgkf", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    fullname: fullname,
                    phoneNumber: phoneNumber,
                    origine: origin
                }),
            });
            const resJson = await res;
            if (res.status === 200) {
                setFullName("");
                setPhoneNumber("");
                setResponse("Nous vous contactons rapidement.");
            } else {
                setResponse("Oups ! Une erreur s'est produite.");
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <MetaTags
                title='Bienvenue chez JELAM'
                description='Ici nous concrétisons vos idées. Startup innovante, PME ou ETI en expansion, découvrez comment nous rendons vos concepts tangibles.'
                image='assets/img/favicon.png'
                name='Jelam'
            />
            <div className='stg-container'>

                {/* Hero Section */}
                <section>
                    <div className="bringer-hero-block bringer-hero-type01">
                        {/* Main Line */}
                        <div className="stg-row stg-large-gap stg-valign-middle stg-tp-column-reverse">
                            <div className="stg-col-9" data-appear="fade-right" data-unload="fade-left">
                                {/* Title */}
                                <h1 className="bringer-page-title" data-split-appear="fade-up" data-split-unload="fade-up">De l'idée à la réalité, main dans la main vers la réussite</h1>
                                <h2 className="stg-top-gap">Donnons vie à vos <b className="jelam-special-word">idées</b> !</h2>
                                <div className="stg-top-gap">
                                    <p className="bringer-large-text">Que vous soyez une <b className="jelam-special-word">STARTUP</b> avec une idée révolutionnaire, une <b className="jelam-special-word">PME</b>ou une <b className="jelam-special-word">ETI</b> cherchant à évoluer</p>
                                    <p><b>Ensemble</b>, nous rendons vos idées tangibles et profitables. Pour y parvenir nous mettons en commun vos ambitions, vos enjeux, votre savoir-faire et notre expertise pour réaliser des produits numériques impactant, ouvrant la voie de la croissance, dans un contexte bienveillant et stimulant.</p>
                                    <p>Nous tirons le meilleur parti du dynamisme des Startups et de l'expérience des PME, ETI pour concevoir des solutions numériques innovantes avec pragmatisme et réalisme.</p>
                                </div>
                            </div>
                            <div className="stg-col-6 stg-tp-bottom-gap-l stg-m-bottom-gap" data-unload="fade-right" data-appear="fade-left">
                                <div className="bringer-parallax-media">
                                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/home/main.jpg" alt="Bienvenue chez JELAM" width="444" height="667" />
                                </div>
                            </div>
                        </div>{/* .stg-row */}
                    </div>{/* .bringer-hero-block */}
                </section>

                {/* <div className="bringer-parallax-media jelam-parallax-image">
                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/home/rocket_right.png" alt="Bienvenue chez JELAM" width="300" height="600" />
                </div> */}

                {/* Section: You are */}
                <section>
                    <div className="stg-row">
                        <div className="stg-col-2 stg-tp-bottom-gap-l" data-unload="fade-left">
                            <div className="bringer-sticky-block">
                                <h2>Vous voulez innover</h2>
                                <p className="bringer-large-text">Nous agissons à vos côtés pour la réussite de vos projets.</p>
                                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/home/cii.jpg" alt="Accréditation CII" width="444" height="667" />
                            </div>
                        </div>
                        <div className="stg-col-10">
                            <div className="bringer-grid-3cols" data-stagger-appear="zoom-in" data-stagger-unload="zoom-out" data-threshold="0.5" data-stagger-delay="150">

                                {/* Customer Type Card */}
                                <div className="bringer-price-card-item bringer-block">
                                    <div className="bringer-price-card-title">
                                        <h6>Vous êtes une</h6>
                                        <h3>STARTUP<span className="bringer-accent">.</span></h3>
                                        <h5>Accélérons votre projet et inspirons la confiance</h5>
                                        <ul>
                                            <li>
                                                Challengeons, structurons et validons vos idées
                                            </li>
                                            <li>
                                                Identifions vos marchés
                                            </li>
                                            <li>
                                                Réduisons vos risques et vos coûts
                                            </li>
                                            <li>
                                                Révélons votre potentiel et séduisez les investisseurs
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="bringer-price-card-footer">
                                        <span className="bringer-label">Valorisons votre idée</span>
                                        <a href="/startup" className="bringer-button is-fullwidth">Concrétisons votre projet</a>
                                    </div>
                                </div>{/* .bringer-block */}

                                {/* Customer Type Card */}
                                <div className="bringer-price-card-item bringer-block">
                                    <div className="bringer-price-card-title">
                                        <h6>Vous êtes une</h6>
                                        <h3>PME ou ETI<span className="bringer-accent">.</span></h3>
                                        <h5>Ne subissez plus, ensemble avançons avec confiance !</h5>
                                        <ul>
                                            <li>
                                                Pilotons votre transformation numérique
                                            </li>
                                            <li>
                                                Assurons la pérennité et la compétitivité de votre entreprise
                                            </li>
                                            <li>
                                                Établissons votre leadership sur votre marché
                                            </li>
                                            <li>
                                                Dynamisons votre marque employeur pour attirer et fidéliser les talents
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="bringer-price-card-footer">
                                        <span className="bringer-label">Restez dans la course</span>
                                        <a href="/company" className="bringer-button is-fullwidth">Accélérons votre mutation</a>
                                    </div>
                                </div>{/* .bringer-block */}
                                {/* Customer Type Card */}
                                <div className="bringer-price-card-item bringer-block">
                                    <div className="bringer-price-card-title">
                                        <h6>Vous souhaitez devenir</h6>
                                        <h3>FONDATEUR<span className="bringer-accent">.</span></h3>
                                        <h5>Donnons vie à vos idées et faisons avancer le monde</h5>
                                        <ul>
                                            <li>
                                                Créons ensemble un cadre stimulant et bienveillant
                                            </li>
                                            <li>
                                                Transformons votre idée en produit concret
                                            </li>
                                            <li>
                                                Accélérons la conquête du marché
                                            </li>
                                            <li>
                                                Partageons les risques et réussissons ensemble
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="bringer-price-card-footer">
                                        <span className="bringer-label">Commençons l’aventure</span>
                                        <a href="/founder" className="bringer-button is-fullwidth">Réalisons votre rêve</a>
                                    </div>
                                </div> {/* .bringer-block */}
                            </div>{/* .bringer-grid */}
                        </div>
                    </div>
                </section>

                {/* Section: Raison d'être */}
                <section className="backlight-top">
                    <div className="stg-row stg-large-gap stg-m-normal-gap">
                        <div className="stg-col-6 stg-tp-bottom-gap-l stg-m-bottom-gap" data-unload="fade-left">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/home/aboutus.jpg" alt="Notre raison d'être" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-vertical-space-between" data-unload="fade-right">
                            <div>
                                <h2>Nous croyons en vous.</h2>
                                <p className="bringer-large-text">Vous aider à créer est notre raison d’être.</p>
                                <p>Nous croyons que dans le bon contexte, bien entouré, bien guidé, l’Homme a une créativité sans limite et que de l’imaginaire à la réalité, il n’y a qu’un pas.</p>
                                <p>Chaque jour, nous nous consacrons à établir cet environnement stimulant où les idées foisonnent, se concrétisent et deviennent profitables et accessibles. Chacune de nos actions visent à mettre à disposition l’écosystème idéal qui permettra à chacun de donner du sens à son activité, de changer sa vie, de gagner sa liberté.</p>
                                <p>Chez JELAM, nous créons les conditions d’un avenir différent.</p>
                            </div>
                            <a href="/aboutus" className="bringer-icon-link">
                                <div className="bringer-icon-wrap">
                                    <i className="bringer-icon bringer-icon-explore"></i>
                                </div>
                                <div className="bringer-icon-link-content">
                                    <h6>
                                        Nous sommes une
                                        <br />
                                        Équipe passionnée.
                                    </h6>
                                    <span className="bringer-label">Qui sommes-nous ?</span>
                                </div>
                            </a>{/* .bringer-icon-link */}
                        </div>
                    </div>{/* .stg-row */}
                </section>

                {/* Section: Steps */}
                <section className="backlight-top">
                    {/* Section Title */}
                    <div className="stg-row bringer-section-title">
                        <div className="stg-col-8 stg-offset-2">
                            <div className="align-center">
                                <h2 data-appear="fade-up" data-unload="fade-up">Startups</h2>
                                <p className="bringer-large-text is-short" data-appear="fade-up" data-unload="fade-up" data-delay="100">Ensemble, propulsons votre startup vers le succès.</p>
                            </div>
                        </div>
                    </div>
                    <div className="stg-row stg-valign-middle stg-large-gap">
                        <div className="stg-col-6 stg-tp-bottom-gap-l" data-appear="fade-right" data-unload="fade-left">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="img/null.png" data-src="assets/img/home/startup.jpg" alt="Démonstration startup" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6" data-unload="fade-right" data-delay="100">
                            <div className="bringer-grid-2cols stg-normal-gap" data-stagger-appear="fade-left">
                                {/* Grid Item */}
                                <div className="bringer-subgrid-item stg-bottom-gap stg-m-bottom-gap-s">
                                    <div className="bringer-title-with-meta">
                                        <span className="bringer-label">01.</span>
                                        <h5 className='jelam-accented-title'>Ciblage & Usage</h5>
                                    </div>
                                    <div>Découvrez qui utilisera votre produit et comment. Ensemble, nous clarifions les usages pour un impact maximal.</div>
                                </div>

                                {/* Grid Item */}
                                <div className="bringer-subgrid-item stg-bottom-gap stg-m-bottom-gap-s">
                                    <div className="bringer-title-with-meta">
                                        <span className="bringer-label">02.</span>
                                        <h5 className='jelam-accented-title'>Alignement et renouveau</h5>
                                    </div>
                                    <div>Voyez votre idée, votre technologie se transformer en une solution qui résonne avec votre marché et apporte une vraie valeur à vos utilisateurs.</div>
                                </div>

                                {/* Grid Item */}
                                <div className="bringer-subgrid-item stg-m-bottom-gap-s">
                                    <div className="bringer-title-with-meta">
                                        <span className="bringer-label">03.</span>
                                        <h5 className='jelam-accented-title'>Atelier IT Agile</h5>
                                    </div>
                                    <div>Vivez la concrétisation structurée de votre vision avec une solution qui dépasse les attentes du marché et change la donne pour vos utilisateurs.</div>
                                </div>

                                {/* Grid Item */}
                                <div className="bringer-subgrid-item">
                                    <div className="bringer-title-with-meta">
                                        <span className="bringer-label">04.</span>
                                        <h5 className='jelam-accented-title'>Vers l'infini et au-delà</h5>
                                    </div>
                                    <div>Présentez au monde une innovation qui transforme des vies et marque le début d'une nouvelle ère.</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* Learn More */}
                    <div className="align-right">
                        <a href="/productstudio" className="bringer-arrow-link">Boostez votre stratégie dès maintenant</a>
                    </div>
                </section>

                {/* Section: Steps */}
                <section className="backlight-top">
                    {/* Section Title */}
                    <div className="stg-row bringer-section-title">
                        <div className="stg-col-8 stg-offset-2">
                            <div className="align-center">
                                <h2 data-appear="fade-up" data-unload="fade-up">PME, ETI</h2>
                                <p className="bringer-large-text is-short" data-appear="fade-up" data-unload="fade-up" data-delay="100">Ensemble, innovons et développons votre potentiel.</p>
                            </div>
                        </div>
                    </div>
                    <div className="stg-row stg-valign-middle stg-large-gap">
                        <div className="stg-col-6" data-unload="fade-right" data-delay="100">
                            <div className="bringer-grid-2cols stg-normal-gap" data-stagger-appear="fade-left">
                                {/* Grid Item */}
                                <div className="bringer-subgrid-item stg-bottom-gap stg-m-bottom-gap-s">
                                    <div className="bringer-title-with-meta">
                                        <span className="bringer-label">01.</span>
                                        <h5 className='jelam-accented-title'>Allumage d'étincelle</h5>
                                    </div>
                                    <div>Exprimez votre vision, vos aspirations et vos défis et ensemble révélons l'étincelle qui propulsera la croissance de votre entreprise.</div>
                                </div>

                                {/* Grid Item */}
                                <div className="bringer-subgrid-item stg-bottom-gap stg-m-bottom-gap-s">
                                    <div className="bringer-title-with-meta">
                                        <span className="bringer-label">02.</span>
                                        <h5 className='jelam-accented-title'>Conception sur mesure</h5>
                                    </div>
                                    <div>Découvrez des idées uniques, créées rien que pour vous afin que l'essence de votre entreprise fasse partie intégrante du résultat final.</div>
                                </div>

                                {/* Grid Item */}
                                <div className="bringer-subgrid-item stg-m-bottom-gap-s">
                                    <div className="bringer-title-with-meta">
                                        <span className="bringer-label">03.</span>
                                        <h5 className='jelam-accented-title'>Atelier IT Artisanal</h5>
                                    </div>
                                    <div>Voyez votre vision prendre vie grâce au savoir-faire minutieux de notre équipe d'experts, pour un résultat qui dépasse vos attentes.</div>
                                </div>

                                {/* Grid Item */}
                                <div className="bringer-subgrid-item">
                                    <div className="bringer-title-with-meta">
                                        <span className="bringer-label">04.</span>
                                        <h5 className='jelam-accented-title'>Vers l'infini et au-delà</h5>
                                    </div>
                                    <div>Révélez votre création au monde, maximisons votre croissance et célébrons votre réussite.</div>
                                </div>
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-bottom-gap-l" data-appear="fade-right" data-unload="fade-left">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="img/null.png" data-src="assets/img/home/PME.jpg" alt="Industrie 4.0" width="960" height="960" />
                            </div>
                        </div>
                    </div>
                    {/* Learn More */}
                    <div className="align-left">
                        <a href="/productstudio" className="bringer-arrow-link">Explorez nos solutions pour les PME, ETI</a>
                    </div>
                </section>

                {/* Section: Services */}
                <section className="backlight-bottom divider-top">
                    {/* Section Intro */}
                    <div className="stg-row stg-large-gap stg-bottom-gap-l">
                        <div className="stg-col-6 stg-tp-col-8 stg-tp-bottom-gap-l" data-unload="fade-left">
                            <h2 data-split-appear="fade-up">Fondateurs</h2>
                        </div>
                        <div className="stg-col-6 stg-tp-col-9 stg-tp-offset-3" data-unload="fade-right">
                            <h3>Un soutien sur mesure aux entrepreneurs innovants</h3>
                            <p className="bringer-large-text" data-split-appear="fade-up" data-delay="100"></p>
                            <p data-appear="fade-up" data-delay="200">Vous avez l’audace d’innover et l’envie de vous lancer ? Nous partageons cette passion pour l'aventure entrepreneuriale. Chez Jelam, nous sommes à vos côtés pour sécuriser votre parcours, avec une approche pragmatique et centrée sur le marché. Ensemble, transformons votre vision en réalité.</p>
                        </div>
                    </div>
                    {/* Services List */}
                    <div className="bringer-detailed-list-wrap" data-appear="fade-up" data-unload="fade-up">
                        <ul className="bringer-detailed-list">
                            <li>
                                <div className="bringer-detailed-list-title">
                                    <h4 className='jelam-accented-title'>Idéation<span className="bringer-accent">.</span></h4>
                                </div>
                                <div className="bringer-detailed-list-description">
                                    <p>Explorez des idées puissantes avec une équipe qui transforme vos visions en opportunités concrètes.</p>
                                </div>
                                <div className="bringer-detailed-list-button">
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                <a href="./startupstudio"></a>
                            </li>
                            <li>
                                <div className="bringer-detailed-list-title">
                                    <h4 className='jelam-accented-title'>Atelier IT Artisanal<span className="bringer-accent">.</span></h4>
                                </div>
                                <div className="bringer-detailed-list-description">
                                    <p>Concrétisez votre vision et bénéficiez d'un soutien expert pour créer un produit impactant.</p>
                                </div>
                                <div className="bringer-detailed-list-button">
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                <a href="./startupstudio"></a>
                            </li>
                            <li>
                                <div className="bringer-detailed-list-title">
                                    <h4 className='jelam-accented-title'>Stratégie<span className="bringer-accent">.</span></h4>
                                </div>
                                <div className="bringer-detailed-list-description">
                                    <p>Prenez des décisions éclairées qui propulsent votre projet, avec la force d'une stratégie solide.</p>
                                </div>
                                <div className="bringer-detailed-list-button">
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                <a href="./startupstudio"></a>
                            </li>
                            <li>
                                <div className="bringer-detailed-list-title">
                                    <h4 className='jelam-accented-title'>Entrepreneuriat<span className="bringer-accent">.</span></h4>
                                </div>
                                <div className="bringer-detailed-list-description">
                                    <p>Dirigez votre Startup et transformez votre ambition en succès avec un partenaire qui vous guide à chaque étape.</p>
                                </div>
                                <div className="bringer-detailed-list-button">
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                <a href="./startupstudio"></a>
                            </li>
                        </ul>
                    </div>{/* .bringer-detailed-list-wrap */}
                    <div className="align-center stg-top-gap-l" data-appear="fade-up" data-unload="fade-up">
                        <a href="./startupstudio" className="bringer-button">Commencez dès aujourd’hui</a>
                    </div>
                </section>

                {/* Section: Counters */}
                <section data-padding="none">
                    <div className="bringer-grid-4cols bringer-tp-grid-2cols bringer-m-grid-2cols stg-m-small-gap" data-stagger-appear="zoom-in" data-stagger-unload="zoom-out">
                        {/* Counter Item */}
                        <div className="bringer-counter bringer-block" data-delay="3000">
                            <div className="bringer-counter-label">Réduction du Time to Market</div>
                            <div className="bringer-counter-number" data-suffix="%+">30</div>
                        </div>{/* .bringer-counter */}
                        {/* Counter Item */}
                        <div className="bringer-counter bringer-block" data-delay="3000">
                            <div className="bringer-counter-number" data-suffix="%+">50</div>
                            <div className="bringer-counter-label stg-top-gap">Efficacité opérationnelle</div>
                        </div>{/* .bringer-counter */}
                        {/* Counter Item */}
                        <div className="bringer-counter bringer-block" data-delay="3000">
                            <div className="bringer-counter-label">Risques diminués</div>
                            <div className="bringer-counter-number" data-suffix="%">30</div>
                        </div>{/* .bringer-counter */}
                        {/* Counter Item */}
                        <div className="bringer-counter bringer-block is-accented" data-delay="3000">
                            <div className="bringer-counter-number" data-suffix="%+">25</div>
                            <div className="bringer-counter-label stg-top-gap">Croissance</div>
                        </div>{/* .bringer-counter */}
                    </div>{/* .bringer-grid */}
                </section>

                {/* Section: Prices */}
                <section>
                    {/* Section Title */}
                    <div className="stg-row stg-bottom-gap" data-unload="fade-up">
                        <div className="stg-col-8 stg-offset-2 stg-tp-col-6 stg-tp-offset-3">
                            <div className="align-center">
                                <h2 data-split-appear="fade-up">Startup ou Product Studio ❓</h2>
                            </div>
                        </div>
                    </div>
                    <div className="stg-row stg-bottom-gap-l" data-unload="fade-up" data-delay="100">
                        <div className="stg-col-6 stg-offset-3 stg-tp-col-6 stg-tp-offset-3">
                            <div className="align-center">
                                <p className="bringer-large-text" data-appear="fade-up" data-delay="100">
                                    Parce que chaque idée mérite d'être explorée et chaque projet d'être soutenu.
                                </p>
                                <h3>
                                    2 offres : 1 approche pour Réussir !
                                </h3>
                            </div>
                        </div>
                    </div>

                    {/* Price Table Carousel */}
                    <div className="bringer-grid-2cols" data-appear="fade-up" data-delay="200" data-unload="fade-up">
                        {/* Carousel Item 01 */}
                        <div className="bringer-block bringer-price-card-item">
                            <div className='className="bringer-price-card-title"'>
                                <h3>Startup Studio<span className="bringer-accent">.</span></h3>
                                <p>Ensemble, nous cocréons notre startup, qu’elle vienne de nos idées ou des vôtres.</p>
                                <div className="bringer-price-wrapper">
                                    <div className="bringer-label">Cofondation de</div>
                                    <h2>STARTUP</h2>
                                </div>
                                <div className="bringer-label bringer-highlight stg-top-gap">Les points clés</div>
                                <ul className="bringer-marked-list">
                                    <li>Co-création d'une startup, ensemble, à partir d'une idée</li>
                                    <li>Structuration de l'idéation au lancement de l'entreprise</li>
                                    <li>Collaboration et accompagnement au lancement du produit</li>
                                    <li>Accompagnement à la création d'entreprise</li>
                                </ul>
                            </div>
                            <div className='bringer-price-card-footer'>
                                <a href="./startupstudio" className="bringer-button is-fullwidth">Développons une idée ensemble</a>
                            </div>
                        </div>{/* .bringer-carousel-card */}

                        {/* Carousel Item 02 */}
                        <div className="bringer-block bringer-price-card-item">
                            <div className='className="bringer-price-card-title"'>
                                <h3>Product Studio<span className="bringer-accent">.</span></h3>
                                <p>Ensemble, nous structurons, développons et lançons votre nouveau produit ou service.</p>
                                <div className="bringer-price-wrapper">
                                    <div className="bringer-label">Conseils & Services aux</div>
                                    <h2>STARTUPS, PME, ETI</h2>
                                </div>
                                <div className="bringer-label stg-top-gap bringer-highlight">Les points clés</div>
                                <ul className="bringer-marked-list">
                                    <li>Conception et lancement de nouveaux produits/services</li>
                                    <li>Structuration de l'idéation au lancement commercial</li>
                                    <li>Conseil pour optimiser le retour sur investissement (ROI)</li>
                                    <li>Accompagnement stratégique à l'innovation</li>
                                </ul>
                            </div>
                            <div className='bringer-price-card-footer'>
                                <a href="./productstudio" className="bringer-button is-fullwidth">Agissons pour votre croissance</a>
                            </div>
                        </div>{/* .bringer-carousel-card */}
                    </div>{/* .bringer-carousel */}
                </section>

                {/* Section: What Makes Us Best */}
                <section className="backlight-bottom">
                    {/* Section Title */}
                    <div className="stg-row bringer-section-title">
                        <div className="stg-col-8 stg-offset-2">
                            <div className="align-center">
                                <h2 data-appear="fade-up" data-unload="fade-up">Malgrè nos nombreux talents</h2>
                                <h3 data-appear="fade-up" data-unload="fade-up">Nous ne sommes pas</h3>
                                <p className="bringer-large-text" data-appear="fade-up" data-unload="fade-up" data-delay="100">(mais nous connaissons de brillantes personnes qui le sont)</p>
                            </div>
                        </div>
                    </div>
                    {/* Content Grid */}
                    <div className="bringer-grid-2cols bringer-tp-grid-1col" data-stagger-appear="zoom-in" data-stagger-delay="100" data-stagger-unload="fade-up">
                        {/* Card Item 01 */}
                        <div className="bringer-block">
                            <h6>Des techniciens de maintenance<span className="bringer-accent">.</span></h6>
                            <p>Vous cherchez un réparateur pour maintenir vos logiciels ?</p>
                            <p>Chez Jelam, nous pensons que vos idées méritent plus. Nos studios vous accompagnent dans la création de produits innovants, en vous aidant à dépasser les solutions existantes pour développer des applications sur mesure qui répondent à vos ambitions.</p>
                        </div>{/* .bringer-block */}

                        {/* Card Item 02 */}
                        <div className="bringer-block">
                            <h6>Une agence Web<span className="bringer-accent">.</span></h6>
                            <p>Nous ne créons pas de sites internet vitrine, de site Wordpress ou de boutique e-commerce.</p>
                            <p>Mais si vous aspirez à créer un produit unique, véritable reflet de votre projet innovant, nous sommes là pour co-créer avec vous des solutions sur mesure, conçues pour vous propulser au-delà de l'ordinaire.</p>
                        </div>{/* .bringer-block */}

                        {/* Card Item 03 */}
                        <div className="bringer-block">
                            <h6>Un cabinet de conseil en informatique (ESN)<span className="bringer-accent">.</span></h6>
                            <p>Cherchez-vous simplement à combler un besoin technique avec un développeur ?</p>
                            <p>Nous croyons que vos projets méritent une vision plus globale. Chez Jelam, nous ne nous contentons pas de fournir des compétences, nous nous engageons à vos côtés pour concevoir et développer des produits innovants qui incarnent véritablement vos idées.</p>
                        </div>{/* .bringer-block */}

                        {/* Card Item 04 */}
                        <div className="bringer-block">
                            <h6>Des investisseurs<span className="bringer-accent">.</span></h6>
                            <p>Vous pensez qu’un soutien financier suffit pour faire décoller votre projet ?</p>
                            <p>Chez Jelam, nous savons que l’argent seul ne suffit pas. En tant que Startup Studio, notre rôle va bien au-delà de l'investissement financier. Nous co-fondons et co-créons avec vous, en investissant notre expertise technique et stratégique pour transformer vos idées en succès durables.</p>
                        </div>{/* .bringer-block */}
                    </div>
                </section>

                {/* Section: Portfolio Carousel */}
                <section className="is-fullwidth is-stretched">
                    {/* Section Title */}
                    <div className="stg-row bringer-section-title is-boxed">
                        <div className="stg-col-8 stg-offset-2">
                            <div className="align-center">
                                <h2 data-appear="fade-up" data-unload="fade-up">Ils nous font confiance</h2>
                            </div>
                        </div>
                    </div>
                    {/* Portfolio Carousel */}
                    <div className="swiper bringer-carousel stg-bottom-gap-l" data-appear="fade-up" data-delay="200" data-unload="fade-up" data-count="4" data-m-count="1.5">
                        <div className="swiper-wrapper">

                            {/* Card Item 02 */}
                            <div className="bringer-block bringer-portfolio-card swiper-slide">
                                <div className="bringer-portfolio-card-image">
                                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/partners/latelierit.jpg" alt="L'atelier IT" width="1200" height="1200" />
                                </div>
                                <div className="bringer-portfolio-card-footer">
                                    <div className="bringer-portfolio-card-title">
                                        <h6>L'atelier IT</h6>
                                    </div>
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                {/* eslint-disable-next-line */}
                                <a href="https://www.latelierit.fr" target="_blank" rel="noreferrer"></a>
                            </div>{/* .bringer-portfolio-card */}

                            {/* Card Item 02 */}
                            <div className="bringer-block bringer-portfolio-card swiper-slide">
                                <div className="bringer-portfolio-card-image">
                                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/partners/Hobud.jpg" alt="Hôbud" width="1200" height="1200" />
                                </div>
                                <div className="bringer-portfolio-card-footer">
                                    <div className="bringer-portfolio-card-title">
                                        <h6>Hôbud</h6>
                                    </div>
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                {/* eslint-disable-next-line */}
                                <a href="https://www.hobud.com" target="_blank" rel="noreferrer"></a>
                            </div>{/* .bringer-portfolio-card */}

                            {/* Card Item 03 */}
                            <div className="bringer-block bringer-portfolio-card swiper-slide">
                                <div className="bringer-portfolio-card-image">
                                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/partners/logo_Globe_Climber.jpg" alt="Globe Climber" width="1200" height="1200" />
                                </div>
                                <div className="bringer-portfolio-card-footer">
                                    <div className="bringer-portfolio-card-title">
                                        <h6>Globe Climber</h6>
                                    </div>
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                {/* eslint-disable-next-line */}
                                <a href="https://globeclimber.com" target="_blank" rel="noreferrer"></a>
                            </div>{/* .bringer-portfolio-card */}

                            {/* Card Item 04 */}
                            <div className="bringer-block bringer-portfolio-card swiper-slide">
                                <div className="bringer-portfolio-card-image">
                                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/partners/Castlefrog.jpg" alt="CastleFrog" width="1200" height="1200" />
                                </div>
                                <div className="bringer-portfolio-card-footer">
                                    <div className="bringer-portfolio-card-title">
                                        <h6>CastleFrog</h6>
                                    </div>
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                {/* eslint-disable-next-line */}
                                <a href="https://castlefrog.fr" target="_blank" rel="noreferrer"></a>
                            </div>{/* .bringer-portfolio-card */}

                            {/* Card Item 05 */}
                            <div className="bringer-block bringer-portfolio-card swiper-slide">
                                <div className="bringer-portfolio-card-image">
                                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/partners/initiative_essonne.jpg" alt="Initiative Essonne" width="1200" height="1200" />
                                </div>
                                <div className="bringer-portfolio-card-footer">
                                    <div className="bringer-portfolio-card-title">
                                        <h6>Initiative Essonne</h6>
                                    </div>
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                {/* eslint-disable-next-line */}
                                <a href="https://www.initiative-essonne.com" target="_blank" rel="noreferrer"></a>
                            </div>{/* .bringer-portfolio-card */}

                            {/* Card Item 07 */}
                            <div className="bringer-block bringer-portfolio-card swiper-slide">
                                <div className="bringer-portfolio-card-image">
                                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/partners/logo_paris_saclay.jpg" alt="Agglomération Paris Saclay" width="1200" height="1200" />
                                </div>
                                <div className="bringer-portfolio-card-footer">
                                    <div className="bringer-portfolio-card-title">
                                        <h6>Agglomération Paris Saclay</h6>
                                    </div>
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                {/* eslint-disable-next-line */}
                                <a href="https://www.paris-saclay.com/accueil-3.html" target="_blank" rel="noreferrer"></a>
                            </div>{/* .bringer-portfolio-card */}

                            {/* Card Item 06 */}
                            <div className="bringer-block bringer-portfolio-card swiper-slide">
                                <div className="bringer-portfolio-card-image">
                                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/partners/logo_BPVF.jpg" alt="Banque Populaire" width="1200" height="1200" />
                                </div>
                                <div className="bringer-portfolio-card-footer">
                                    <div className="bringer-portfolio-card-title">
                                        <h6>Banque Populaire Val de France</h6>
                                    </div>
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                {/* eslint-disable-next-line */}
                                <a href="https://www.banquepopulaire.fr/valdefrance/" target="_blank" rel="noreferrer"></a>
                            </div>{/* .bringer-portfolio-card */}


                            {/* Card Item 08 */}
                            <div className="bringer-block bringer-portfolio-card swiper-slide">
                                <div className="bringer-portfolio-card-image">
                                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/partners/logo_self_and_innov.jpg" alt="Self and Innov" width="1200" height="1200" />
                                </div>
                                <div className="bringer-portfolio-card-footer">
                                    <div className="bringer-portfolio-card-title">
                                        <h6>Self and Innov</h6>
                                    </div>
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                {/* eslint-disable-next-line */}
                                <a href="https://www.self-and-innov.fr" target="_blank" rel="noreferrer"></a>
                            </div>{/* .bringer-portfolio-card */}

                            {/* Card Item 08 */}
                            <div className="bringer-block bringer-portfolio-card swiper-slide">
                                <div className="bringer-portfolio-card-image">
                                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/partners/logo_karma.jpeg" alt="Agence Kar'ma" width="1200" height="1200" />
                                </div>
                                <div className="bringer-portfolio-card-footer">
                                    <div className="bringer-portfolio-card-title">
                                        <h6>Agence Kar'ma</h6>
                                    </div>
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                {/* eslint-disable-next-line */}
                                <a href="https://www.agence-kar-ma.fr" target="_blank" rel="noreferrer"></a>
                            </div>{/* .bringer-portfolio-card */}

                            {/* Card Item 08
                            <div className="bringer-block bringer-portfolio-card swiper-slide">
                                <div className="bringer-portfolio-card-image">
                                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/partners/logo_karma.jpeg" alt="Agence Kar'ma" width="1200" height="1200" />
                                </div>
                                <div className="bringer-portfolio-card-footer">
                                    <div className="bringer-portfolio-card-title">
                                        <h6>Cabinet LRF Avocats Conseil</h6>
                                    </div>
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                {/* eslint-disable-next-line 
                                <a href="https://lrf-avocats.fr" target="_blank" rel="noreferrer"></a>
                            </div>{/* .bringer-portfolio-card */}

                            {/* Card Item 01 */}
                            <div className="bringer-block bringer-portfolio-card swiper-slide">
                                <div className="bringer-portfolio-card-image">
                                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/partners/QSTOM.jpg" alt="QSTOM" width="1200" height="1200" />
                                </div>
                                <div className="bringer-portfolio-card-footer">
                                    <div className="bringer-portfolio-card-title">
                                        <h6>QSTOM</h6>
                                    </div>
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                {/* eslint-disable-next-line */}
                                <a href="https://qstom.fr" target="_blank" rel="noreferrer"></a>
                            </div>{/* .bringer-portfolio-card */}
                        </div>
                        {/* Pagination */}
                        <div className="swiper-pagination bringer-dots"></div>
                    </div>{/* .bringer-portfolio-carousel */}
                </section>


                {/* Section: CTA Form */}
                <section data-padding="bottom">
                    <div className="bringer-masked-cta bringer-masked-block" data-unload="fade-down">
                        <form onSubmit={handleSubmit} method="post" data-fill-error="Veuillez compléter les champs obligatoires." className="bringer-contact-form is-short bringer-masked-media" data-appear="fade-up">
                            <div className="bringer-form-content bringer-cta-form">
                                <div className="bringer-cta-form-content" data-appear="fade-up" data-delay="100">
                                    <div className="bringer-cta-title">Ensemble, donnons vie à votre idée.</div>
                                    <input type="text" id="fullname" name="fullname" placeholder="Votre nom" required value={fullname} onChange={(e) => setFullName(e.target.value)} />
                                    <input type="tel" pattern="^(\+33|0)[1-9](\d{2}){4}$" id="phoneNumber" name="phoneNumber" placeholder="Votre numéro" required value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                </div>
                                <div className="bringer-cta-form-button" data-appear="fade-up" data-delay="200">
                                    <button type="submit">
                                        <span className="bringer-icon bringer-icon-arrow-submit"></span>
                                    </button>
                                </div>
                                <div className="bringer-contact-form__response"><span>{response}</span></div>
                            </div>
                            <span className="bringer-form-spinner"></span>
                        </form>
                        <div className="bringer-masked-cta-content bringer-masked-content at-top-right">
                            <p className="bringer-large-text" data-appear="fade-down">De l'idée à l'action ✨</p>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Home