import { React, useState } from 'react'
import { useLocation } from 'react-router-dom';
import MetaTags from '../components/MetaTags';

function Aboutus() {

  const [fullname, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [response, setResponse] = useState("");
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      let origin = location.pathname.length > 1 ? location.pathname.replace('/', '').charAt(0).toUpperCase() + location.pathname.slice(2) : 'Home';

      let res = await fetch("https://hook.eu2.make.com/iauzb7zypebiqon5rdbslwtnmglbbgkf", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fullname: fullname,
          phoneNumber: phoneNumber,
          origine: origin
        }),
      });
      const resJson = await res;
      if (res.status === 200) {
        setFullName("");
        setPhoneNumber("");
        setResponse("Nous vous contactons rapidement.");
      } else {
        setResponse("Oups ! Une erreur s'est produite.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <MetaTags
        title='Qui sommes-nous ?'
        description='Venez rencontrer l’équipe de JELAM. Découvrez nos passions, nos valeurs, et ce qui guide nos actions pour innover chaque jour.'
        image='assets/img/favicon.png'
        name='Jelam'
      />
      <div className='stg-container'>
        {/* Section: Page Title */}
        <section className="backlight-bottom">
          <div className="stg-row">
            <div className="stg-col-12 align-center stg-tp-col-10 stg-tp-offset-1">
              <h1 className="bringer-page-title" data-appear="fade-up" data-unload="fade-up">Qui sommes-nous ?</h1>
            </div>
          </div>
        </section>

        {/* Section: About Us */}
        <section className="divider-bottom">
          <div className="stg-row stg-large-gap stg-tp-normal-gap">
            <div className="stg-col-6 stg-vertical-space-between stg-tp-bottom-gap-l" data-unload="fade-left">
              <h2 data-split-appear="fade-up">JELAM c’est quoi ?</h2>
              <div className="align-right" data-appear="fade-right">
                <a href="/contact" className="bringer-icon-link">
                  <div className="bringer-icon-link-content">
                    <h6>
                      Libérons
                      <br />
                      votre créativité
                    </h6>
                    <span className="bringer-label">Contactez-nous</span>
                  </div>
                  <div className="bringer-icon-wrap">
                    <i className="bringer-icon bringer-icon-explore"></i>
                  </div>
                </a>{/* .bringer-icon-link */}
              </div>
            </div>
            <div className="stg-col-6" data-appear="fade-left" data-unload="fade-right" data-delay="100">
              <p className="bringer-large-text">Que vous soyez un entrepreneur en lancement, une Startup en quête de structuration, une PME ou une ETI cherchant à innover, nous vous accompagnons dès les premières étapes de votre projet avec une approche sur mesure et collaborative.</p>
              <p>Au cœur de l'écosystème dynamique du plateau de Saclay, JELAM se positionne comme votre partenaire stratégique pour transformer vos idées en réussites tangibles.</p>
              <p><span className="bringer-accent">Innovation et Co-Développement</span> : Avec une solide expérience entrepreneuriale et une expertise reconnue en innovation numérique, nous co-développons des solutions qui transforment vos idées en projets concrets et rentables. Notre statut de Startup et Product Studio et notre accréditation Crédit Impôt Innovation témoignent de notre engagement à vous offrir des outils efficaces pour booster votre croissance, en alliant ambition et pragmatisme.</p>
              <p><span className="bringer-accent">Une Méthodologie Structurée pour Réussir</span> : Nous croyons que chaque idée mérite de voir le jour. C'est pourquoi nous mettons à votre disposition une méthodologie éprouvée qui vous guide à chaque étape – de l'expression de votre idée à sa mise en marché. Vous n'êtes jamais seul dans cette aventure : nous sommes là pour vous épauler, vous challenger, et surtout, pour concrétiser ensemble les innovations de demain.</p>
            </div>
          </div>
        </section>

        {/* Section: Team */}
        <section>
          {/* Section Title */}
          <div className="stg-row bringer-section-title">
            <div className="stg-col-8 stg-offset-2 stg-tp-col-10 stg-tp-offset-1">
              <div className="align-center stg-bottom-gap">
                <h2 data-appear="fade-up" data-unload="fade-up">Rencontrez l'équipe</h2>
                <p className="bringer-large-text" data-split-appear="fade-up" data-split-unload="fade-up" data-delay="100">Nous sommes une équipe de visionnaire pragmatique, passionnée et expérimentée.</p>
              </div>
              <div class="bringer-expand-on-scroll">
                    <img src="assets/img/team/Jelam.jpg" alt="L'équipe" data-unload="fade-up" />
              </div>{/* .bringer-expand-on-scroll */}
            </div>
          </div>
        </section>
        <section>
          <div className="stg-row stg-large-gap stg-bottom-gap-l">
            <div className="stg-col-4 stg-tp-col-8 stg-tp-offset-2 stg-tp-bottom-gap-l" data-appear="fade-right" data-unload="fade-left">
              <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/team/Jean-Noel.jpg" alt="Jean-Noël Malborougt" width="600" height="900" />
            </div>
            <div className="stg-col-8 stg-vertical-space-between" data-unload="fade-right">
              {/* Content */}
              <div>
                <div className="bringer-title-with-label stg-bottom-gap">
                  <h2 data-appear="fade-up">Jean-Noël Malborougt</h2>
                  <span className="bringer-label" data-appear="fade-up" data-delay="100">CEO, Fondateur</span>
                </div>
                <p className="bringer-large-text" data-appear="fade-up" data-delay="150">Le mot de Jean-Noël.</p>
                <p data-appear="fade-up" data-delay="200">Ma passion pour l’innovation est le moteur de ma carrière. J’ai cultivé une expertise en méthodologies d’innovation pendant mon expérience de Responsable Innovation au sein d’IN Groupe, développant des stratégies, de nouveaux services/produits, détectant et collaborant avec des Startups afin de transformer les idées en réalités tangibles.</p>
                <p data-appear="fade-up" data-delay="250">Mon parcours démontre un engagement profond et une volonté de travailler sur la compétitivité des entreprises. Je guide avec succès l’émergence de nouvelles entreprises. Chaque étape de ma carrière illustre mon dévouement à repousser les frontières de l’innovation, toujours avec un œil tourné vers l’avenir et le potentiel de transformation.</p>
              </div>
              {/* Contacts */}
              <div className="bringer-member-contacts">
                <ul className="bringer-socials-list" data-stagger-appear="fade-up">
                  <li>
                    <a href="https://www.linkedin.com/in/jean-noel-malborougt/" target="_blank" rel="noreferrer" className="bringer-socials-linkedin">
                      <i></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://instagram.com/jelam_91" target="_blank" rel="noreferrer" className="bringer-socials-instagram">
                      <i></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* </section>
      <section> */}
          <div className="stg-row stg-large-gap stg-bottom-gap-l">

            <div className="stg-col-8 stg-vertical-space-between" data-unload="fade-right">
              {/* Content */}
              <div>
                <div className="bringer-title-with-label stg-bottom-gap">
                  <h2 data-appear="fade-up">Benjamin Naxos</h2>
                  <span className="bringer-label" data-appear="fade-up" data-delay="100">CTO</span>
                </div>
                <p className="bringer-large-text" data-appear="fade-up" data-delay="150">Le mot de Benjamin.</p>
                <p data-appear="fade-up" data-delay="200">Passionné depuis plus de 20 ans par le développement informatique, j’aime apprendre constamment de nouvelles technologies pour les mettre au service de mes réalisations. Mais mon atout principal, c’est ma casquette de facilitateur. </p>
                <p data-appear="fade-up" data-delay="250">J’accompagne mes équipes avec pédagogie et pragmatisme pour qu’ils utilisent pleinement leur potentiel et qu’ensemble nous menions à bien les projets.</p>
                <p data-appear="fade-up" data-delay="300">Mes années d’expérience et le long chemin d’entrepreneur déjà parcouru me donnent une détermination sans faille pour aller plus loin et relever de nouveaux défis.</p>
              </div>
              {/* Contacts */}
              <div className="bringer-member-contacts">
                <ul className="bringer-socials-list" data-stagger-appear="fade-up">
                  <li>
                    <a href="https://www.linkedin.com/in/benjamin-n-302545203/" target="_blank" rel="noreferrer" className="bringer-socials-linkedin">
                      <i></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="stg-col-4 stg-tp-col-8 stg-tp-offset-2 stg-tp-bottom-gap-l" data-appear="fade-right" data-unload="fade-left">
              <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/team/Benjamin.jpg" alt="Benjamin Naxos" width="600" height="900" />
            </div>
          </div>

          <div className="stg-row stg-large-gap">
            <div className="stg-col-4 stg-tp-col-8 stg-tp-offset-2 stg-tp-bottom-gap-l" data-appear="fade-right" data-unload="fade-left">
              <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/team/Nayah.jpg" alt="Nayah Limea" width="600" height="900" />
            </div>
            <div className="stg-col-8 stg-vertical-space-between" data-unload="fade-right">
              {/* Content */}
              <div>
                <div className="bringer-title-with-label stg-bottom-gap">
                  <h2 data-appear="fade-up">Nayah Limea</h2>
                  <span className="bringer-label" data-appear="fade-up" data-delay="100">Designer Numérique</span>
                </div>
                <p className="bringer-large-text" data-appear="fade-up" data-delay="150">Le mot de Nayah.</p>
                <p data-appear="fade-up" data-delay="200">Je m'appelle Nayah, apprentie designer numérique chez Jelam et étudiante à l'ICAN. J’ai toujours été passionnée par l’art et le graphisme, que ce soit numérique ou print. Pour moi, le design numérique dépasse l'esthétique : c'est la possibilité de transformer une idée en réalité et d'aider les autres à concrétiser leur vision. Curieuse et créative, j’aime explorer de nouveaux horizons, rencontrer des personnes inspirantes et m’immerger dans des projets hors du commun.</p>
                <p data-appear="fade-up" data-delay="250">Chez Jelam, j'ai trouvé un environnement qui cultive l'innovation, le respect du travail collaboratif et l'amélioration continue. Mon approche chaleureuse et mon esprit d’équipe m’aident à créer des connexions authentiques avec chaque projet. Ici, je peux proposer, apprendre, progresser et contribuer à des projets qui rendent la vie plus belle et plus simple. Cette dynamique de co-création me motive chaque jour à donner le meilleur de moi-même. Avec moi, vous trouverez une alliée créative et attentive, prête à relever vos défis et à concrétiser vos idées.</p>
              </div>
              {/* Contacts */}
              <div className="bringer-member-contacts">
                <ul className="bringer-socials-list" data-stagger-appear="fade-up">
                  <li>
                    <a href="https://www.instagram.com/midnight_in_design972" target="_blank" rel="noreferrer" className="bringer-socials-instagram">
                      <i></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/nayah-limea-b04b88232/" target="_blank" rel="noreferrer" className="bringer-socials-linkedin">
                      <i></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>


        </section>

        {/* Section: Our Passion */}
        <section>
          <div className="align-center stg-bottom-gap-l">
            <h2 data-split-appear="fade-up" data-unload="fade-up">Nous sommes guidés par nos valeurs.</h2>
            <p className="bringer-large-text" data-appear="fade-up" data-delay="100" data-unload="fade-up">Chez JELAM, nous cultivons</p>
          </div>

          <div className="bringer-grid-2cols" data-stagger-appear="zoom-in" data-delay="150" data-unload="fade-up">
            {/* Icon Box Icon */}
            <div className="bringer-icon-box bringer-block">
              <h3>La Creativité<span className="bringer-accent">.</span></h3>
              <p>Nous observons le monde, explorons, expérimentons. Nous sommes ouvert à l’inspiration, nous laissons de l’espace et du temps pour créer individuellement et collectivement.</p>
              <div className="bringer-box-icon">
                <i className="bringer-icon bringer-icon-creativity"></i>
              </div>
            </div>{/* .bringer-block */}
            {/* Icon Box Icon */}
            <div className="bringer-icon-box bringer-block">
              <h3>La Bienveillance<span className="bringer-accent">.</span></h3>
              <p>Nous avons le soucis de l’autre et de son bien-être. Nous agissons avec compassion et restons encourageant dans nos messages. Nous sommes indulgents, reconnaissants et accordons à chacun le droit à l’erreur.</p>
              <div className="bringer-box-icon">
                <i className="bringer-icon jelam-icon-heart"></i>
              </div>
            </div>{/* .bringer-block */}
            {/* Icon Box Icon */}
            <div className="bringer-icon-box bringer-block">
              <h3>Le Partage<span className="bringer-accent">.</span></h3>
              <p>Nous partageons nos rêves. Nous offrons le meilleur de nous même. Nous partageons avec tous notre expérience, nos connaissances, notre vision afin d’en ressortir grandi.</p>
              <div className="bringer-box-icon">
                <i className="bringer-icon bringer-icon-collab"></i>
              </div>
            </div>{/* .bringer-block */}
            {/* Icon Box Icon */}
            <div className="bringer-icon-box bringer-block">
              <h3>La Transparence<span className="bringer-accent">.</span></h3>
              <p>Nous savons et communiquons ce que nous voulons accomplir. Nous sommes ambitieux mais réalistes face à la difficulté. Nous inspirons, nous ne mentons pas, nous ne manipulons pas.</p>
              <div className="bringer-box-icon">
                <i className="bringer-icon bringer-icon-impact"></i>
              </div>
            </div>{/* .bringer-block */}
            {/* Icon Box Icon */}
            <div className="bringer-icon-box bringer-block">
              <h3>L'Ouverture d’esprit<span className="bringer-accent">.</span></h3>
              <p>Nous nous remettons en question et nous apprenons de tout ce qui nous entoure, tant de la nature, que de l’homme, que de la société elle-même. Nous prenons en compte les opinions, les critiques constructives même si elles diffèrent des nôtres.</p>
              <div className="bringer-box-icon">
                <i className="bringer-icon jelam-icon-openmind"></i>
              </div>
            </div>{/* .bringer-block */}
            {/* Icon Box Icon */}
            <div className="bringer-icon-box bringer-block">
              <h3>L'Éthique<span className="bringer-accent">.</span></h3>
              <p>Nous traitons les autres et l’environnement avec respect, équité et empathie en leur reconnaissant leur autonomie et leur droit à la dignité. Nous agissons avec intégrité et nous mesurons les conséquences de nos choix et de nos actions.</p>
              <div className="bringer-box-icon">
                <i className="bringer-icon bringer-icon-innovation"></i>
              </div>
            </div>{/* .bringer-block */}
          </div>{/* .bringer-grid */}
        </section>

        {/* Section: What Do I Offer */}
        <section className="backlight-bottom">
          {/* Section Title */}
          <div className="stg-row bringer-section-title">
            <div className="stg-col-8 stg-offset-2">
              <div className="align-center">
                <h2 data-appear="fade-up" data-unload="fade-up">Nos talents (et ceux de nos partenaires) sont nombreux</h2>
              </div>
            </div>
          </div>
          {/* Services Grid */}
          <div className="bringer-grid-3cols bringer-tp-grid-2cols" data-stagger-appear="fade-up" data-delay="100" data-stagger-delay="100" data-unload="fade-up">
            {/* Item 01 */}
            <div className="bringer-block stg-aspect-square stg-vertical-space-between">
              <h5>Entrepreneuriat<span className="bringer-accent">.</span></h5>
              <ul className="bringer-marked-list">
                <li>
                  <h6>Stratégie</h6>
                </li>
                <li>
                  <h6>Business Plan</h6>
                </li>
                <li>
                  <h6>DAF</h6>
                </li>
                <li>
                  <h6>Création d'entreprise</h6>
                </li>
                <li>
                  <h6>Pilotage d'entreprise</h6>
                </li>
              </ul>
            </div>
            {/* Item 02 */}
            <div className="bringer-block stg-aspect-square stg-vertical-space-between">
              <h5>Innovation<span className="bringer-accent">.</span></h5>
              <ul className="bringer-marked-list">
                <li>
                  <h6>Management de l'innovation</h6>
                </li>
                <li>
                  <h6>Open Innovation</h6>
                </li>
                <li>
                  <h6>Gestion du changement</h6>
                </li>
                <li>
                  <h6>Transformation numérique</h6>
                </li>
                <li>
                  <h6>Financement de l'innovation</h6>
                </li>
              </ul>
            </div>
            {/* Item 03 */}
            <div className="bringer-block stg-aspect-square stg-vertical-space-between">
              <h5>Marketing<span className="bringer-accent">.</span></h5>
              <ul className="bringer-marked-list">
                <li>
                  <h6>Management de produit</h6>
                </li>
                <li>
                  <h6>Développement de produit</h6>
                </li>
                <li>
                  <h6>Lancement produit (Go To Market)</h6>
                </li>
                <li>
                  <h6>Identité Visuelle, Marque</h6>
                </li>
                <li>
                  <h6>Mix Marketing</h6>
                </li>
              </ul>
            </div>
            {/* Item 04 */}
            <div className="bringer-block stg-aspect-square stg-vertical-space-between">
              <h5>Développement logiciel<span className="bringer-accent">.</span></h5>
              <ul className="bringer-marked-list">
                <li>
                  <h6>Développement Fullstack</h6>
                </li>
                <li>
                  <h6>Application SAAS</h6>
                </li>
                <li>
                  <h6>Application Mobile</h6>
                </li>
                <li>
                  <h6>Méthode Scrum</h6>
                </li>
                <li>
                  <h6>Devops</h6>
                </li>
              </ul>
            </div>
            {/* Item 05 */}
            <div className="bringer-block stg-aspect-square stg-vertical-space-between">
              <h5>Design<span className="bringer-accent">.</span></h5>
              <ul className="bringer-marked-list">
                <li>
                  <h6>Conception Produit</h6>
                </li>
                <li>
                  <h6>Prototypage</h6>
                </li>
                <li>
                  <h6>Design Thinking</h6>
                </li>
                <li>
                  <h6>UX/UI Design</h6>
                </li>
                <li>
                  <h6>Design Sprint</h6>
                </li>
              </ul>
            </div>
            {/* Item 06 */}
            <div className="bringer-block stg-aspect-square stg-vertical-space-between">
              <h5>Technologies<span className="bringer-accent">.</span></h5>
              <ul className="bringer-marked-list">
                <li>
                  <h6>Cloud</h6>
                </li>
                <li>
                  <h6>Cybersécurité</h6>
                </li>
                <li>
                  <h6>Intelligence Artificielle (IA)</h6>
                </li>
                <li>
                  <h6>Objet Connecté (IoT)</h6>
                </li>
                <li>
                  <h6>Blockchain</h6>
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* Section: CTA Form */}
        <section data-padding="bottom">
          <div className="bringer-masked-cta bringer-masked-block" data-unload="fade-down">
            <form onSubmit={handleSubmit} method="post" data-fill-error="Veuillez compléter les champs obligatoires." className="bringer-contact-form is-short bringer-masked-media" data-appear="fade-up">
              <div className="bringer-form-content bringer-cta-form">
                <div className="bringer-cta-form-content" data-appear="fade-up" data-delay="100">
                  <div className="bringer-cta-title">Envie de rejoindre notre équipage ?</div>
                  <input type="text" id="fullname" name="fullname" placeholder="Votre nom" required value={fullname} onChange={(e) => setFullName(e.target.value)} />
                  <input type="tel" pattern="^(\+33|0)[1-9](\d{2}){4}$" id="phoneNumber" name="phoneNumber" placeholder="Votre numéro" required value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                </div>
                <div className="bringer-cta-form-button" data-appear="fade-up" data-delay="200">
                  <button type="submit">
                    <span className="bringer-icon bringer-icon-arrow-submit"></span>
                  </button>
                </div>
                <div className="bringer-contact-form__response">{response}</div>
              </div>
              <span className="bringer-form-spinner"></span>
            </form>
            <div className="bringer-masked-cta-content bringer-masked-content at-top-right">
              <p className="bringer-large-text" data-appear="fade-down">Échangeons sur votre projet. ✨</p>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Aboutus