import { React, useState } from 'react'
import MetaTags from '../components/MetaTags';

function Contact() {

  const [fullname, setFullName] = useState("");
  const [company, setCompany] = useState("");
  const [contactType, setContactType] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      let res = await fetch("https://hook.eu2.make.com/iauzb7zypebiqon5rdbslwtnmglbbgkf", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fullname: fullname,
          company: company,
          contactType: contactType,
          phoneNumber: phoneNumber,
          email: email,
          message: message
        }),
      });
      const resJson = await res;
      if (res.status === 200) {
        setFullName("");
        setCompany("");
        setContactType("");
        setEmail("");
        setPhoneNumber("");
        setMessage("");
        setResponse("Nous vous contactons rapidement.");
      } else {
        setResponse("Oups ! Une erreur s'est produite.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <MetaTags
        title='À votre écoute'
        description='Nous adorerions écouter votre idée innovante et vous aider à la concrétiser. Contactez-nous pour échanger et découvrir comment nous pouvons vous aider.'
        image='assets/img/inner-pages/idee.jpg'
        name='Jelam'
      />
      <div className='stg-container'>
        {/* Section: Page Title */}
        <section className="backlight-bottom">
          <div className="stg-row stg-bottom-gap-l">
            <div className="stg-col-6 stg-offset-3 align-center">
              <h1 className="bringer-page-title" data-appear="fade-up" data-unload="fade-up">Nous sommes à votre écoute</h1>
              <p className="bringer-large-text" data-appear="fade-up" data-delay="100" data-unload="fade-up">Nous aidons les entreprises à innover avec pragmatisme. Nous pensons que chaque idée, grande ou petite, mérite de briller.</p>
            </div>
          </div>
          <div className="bringer-parallax-media" data-parallax-speed="20" data-appear="fade-up" data-delay="200" data-unload="fade-up">
            <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/idee.jpg" alt="Nous sommes à votre écoute" width="1920" height="960" />
          </div>{/* .bringer-parallax-media */}
        </section>

        {/* Section: About Us */}
        <section>
          {/* Section Title */}
          <div className="stg-row stg-bottom-gap">
            <div className="stg-col-8">
              <h2 data-split-appear="fade-up" data-unload="fade-up">Prêt à allumer l'étincelle 🔥  Échangeons ! 🤝</h2>
            </div>
            <div className="stg-col-4"></div>
          </div>
          {/* Section Content */}
          <div className="stg-row stg-bottom-gap-l">
            <div className="stg-col-6 stg-offset-6" data-appear="fade-up" data-delay="200" data-unload="fade-up">
              <p>Nous croyons au pouvoir de la collaboration et de la passion partagée. Nous ne sommes pas seulement un fournisseur, nous sommes vos partenaires pour faire émerger le potentiel unique de votre créativité. Alors, que vous débordiez d'idées ou que vous commenciez tout juste à vous interroger, contactez-nous et discutons-en !</p>
            </div>
          </div>
          {/* Grid Galery */}
          <div className="bringer-grid-3cols bringer-parallax-media bringer-m-grid-3cols stg-m-small-gap" data-stagger-appear="fade-up" data-delay="200" data-stagger-unload="fade-up">
            <a href="assets/img/inner-pages/contacts02.jpg" className="bringer-lightbox-link" data-size="960x960">
              <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/co-working1.jpg" alt="équipe en réunion" width="960" height="960" />
            </a>
            <a href="assets/img/inner-pages/contacts03.jpg" className="bringer-lightbox-link" data-size="960x960">
              <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/co-working2.jpg" alt="collaboration" width="960" height="960" />
            </a>
            <a href="assets/img/inner-pages/contacts04.jpg" className="bringer-lightbox-link" data-size="960x960">
              <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/co-working4.jpg" alt="brainstorming" width="960" height="960" />
            </a>
          </div>
        </section>

        {/* Section: Let's Talk */}
        <section className="backlight-top divider-bottom">
          {/* Section Title */}
          <div className="stg-row bringer-section-title">
            <div className="stg-col-8 stg-offset-2">
              <div className="align-center">
                <h2 data-appear="fade-up" data-unload="fade-up">Discutons de votre idée 🗣 !</h2>
                <p className="bringer-large-text" data-appear="fade-up" data-delay="100" data-unload="fade-up">Avec nous votre idée est en sécurité</p>
                <p data-appear="fade-up" data-delay="100" data-unload="fade-up">Nous savons combien la confidentialité et la confiance sont essentielles pour faire naître des idées.
                  Votre créativité est précieuse, et nous avons à cœur de la protéger et de la faire grandir à vos côtés.
                </p>
              </div>
            </div>
          </div>
          {/* Contacts Grid */}
          <div className="stg-row" data-stagger-appear="fade-up" data-delay="200" data-stagger-unload="fade-up">
          <div className="stg-col-6 stg-tp-col-6 stg-tp-bottom-gap">
              {/* Email */}
              <div className="bringer-block stg-vertical-space-between">
                <div>
                  <h5>Téléphone : 01 80 86 41 53<span className="bringer-accent">.</span></h5>
                  <ul className="bringer-socials-list stg-small-gap" data-stagger-appear="fade-up" data-stagger-delay="75">
                    <li>
                      <a href="#" className="bringer-slider-prev">
                        <span className="bringer-icon bringer-icon-impact"></span>
                      </a>
                    </li>
                  </ul>
                </div>
                <p>Appelez nous pour échanger sur votre vision. Nous sommes à votre écoute pour identifier comment mettre en oeuvre votre projet.</p>
              </div>
            </div>
            <div className="stg-col-6 stg-tp-col-6 stg-tp-bottom-gap">
              {/* Email */}
              <div className="bringer-block stg-vertical-space-between">
                <div>
                  <h5>Email : hello@jelam.fr<span className="bringer-accent">.</span></h5>
                  <ul className="bringer-socials-list stg-small-gap" data-stagger-appear="fade-up" data-stagger-delay="75">
                    <li>
                      <a href="mailto:hello@jelam.fr" className="bringer-slider-prev">
                        <span className="bringer-icon bringer-icon-explore"></span>
                      </a>
                    </li>
                  </ul>
                </div>
                <p>Envoyez-nous un message détaillé. Nous vous répondrons dans les plus brefs délais pour discuter plus en détail de votre projet créatif.</p>
              </div>
            </div>
            <div className="stg-col-6 stg-tp-col-12">
              {/* Social Media */}
              <div className="bringer-block stg-tp-aspect-rectangle stg-vertical-space-between">
                <div>
                  <h5>Réseaux<span className="bringer-accent">.</span></h5>
                  <ul className="bringer-socials-list stg-small-gap" data-stagger-appear="fade-up" data-stagger-delay="75">
                    <li>
                      <a href="https://www.linkedin.com/company/jelamfr" target="_blank" rel="noreferrer" className="bringer-socials-linkedin">
                        <i></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <p>Suivez-nous sur les réseaux sociaux pour avoir un aperçu de notre monde créatif, de nos connaissances du secteur et de nos projets.</p>
              </div>
            </div>
          </div>

          <div className="align-center stg-top-gap-l" data-unload="zoom-in">
            <h2 data-appear="fade-up" data-unload="fade-up">Nous pouvons aussi aller plus vite !</h2>

            <a href="https://outlook.office.com/bookwithme/user/d0b2081c9a2146838442708b433fa4cf@jelam.fr?anonymous&ep=plink" target="_blank" rel="noreferrer" className="bringer-icon-link bringer-next-post">
              <div className="bringer-icon-link-content">
                <h6>Nous souhaitons que chaque idée ait l'opportunité d'être offerte au monde.</h6>
                <h3>Prenons rendez-vous</h3>
              </div>
              <div className="bringer-icon-wrap">
                <i className="bringer-icon bringer-icon-explore"></i>
              </div>
            </a>{/* .bringer-icon-link */}
          </div>

        </section>

        {/* Section: CTA Form */}
        <section className="backlight-top is-fullwidth">
          <div className="stg-row stg-valign-middle stg-cta-with-image stg-tp-column-reverse">
            <div className="stg-col-5" data-unload="fade-left">
              <div className="bringer-offset-image" data-bg-src="assets/img/cta/ampoule_blanche.jpg" data-appear="fade-up" data-threshold="0.25"></div>
              <form onSubmit={handleSubmit} className="bringer-contact-form bringer-block" data-fill-error="Veuillez compléter les champs obligatoires." data-appear="fade-right" data-threshold="0.25">
                <div className="bringer-form-content">
                  {/* Field: Name */}
                  <label htmlFor="name">Votre nom<span className="bringer-accent">*</span></label>
                  <input type="text" id="fullname" name="fullname" placeholder="Prénom Nom" value={fullname} onChange={(e) => setFullName(e.target.value)} required />
                  {/* Field: Name */}
                  <label htmlFor="company">Votre entreprise</label>
                  <input type="text" id="company" name="company" value={company} onChange={(e) => setCompany(e.target.value)} />
                  {/* Field: Type */}
                  <label htmlFor="contactType">Vous êtes</label>
                  <div className="select-wrapper stg-bottom-gap">
                    <select name="contactType" id="contactType" value={contactType} onChange={(e) => setContactType(e.target.value)} >
                      <option value=""></option>
                      <option value="startup">Startup</option>
                      <option value="company">PME, ETI</option>
                      <option value="founder">Fondateur</option>
                      <option value="investors">Investisseur</option>
                      <option value="other">Autres</option>
                    </select>
                  </div>
                  {/* Field: Telephone  */}
                  <label htmlFor="phoneNumber">Votre numéro de téléphone<span className="bringer-accent">*</span></label>
                  <input type="tel" pattern="^(\+33|0)[1-9](\d{2}){4}$" id="phoneNumber" name="phoneNumber" placeholder="0123456789" required value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                  {/* Field: Email */}
                  <label htmlFor="email">Votre mail<span className="bringer-accent">*</span></label>
                  <input type="email" id="email" name="email" placeholder="email@example.com" required value={email} onChange={(e) => setEmail(e.target.value)} />
                  {/* Field: Message */}
                  <label htmlFor="message">Votre projet<span className="bringer-accent">*</span></label>
                  <textarea id="message" name="message" placeholder="Décrivez-nous votre projet" required value={message} onChange={(e) => setMessage(e.target.value)} ></textarea>
                  {/* Form Button */}
                  <button type="submit" value="Send Message">Contactez-moi</button>
                  <div className="bringer-contact-form__response">{response}</div>
                </div>
                <span className="bringer-form-spinner"></span>
              </form>
            </div>
            <div className="stg-col-6 stg-offset-1" data-unload="fade-right">
              <div className="bringer-cta-form-content">
                <div className="bringer-cta-form-title" data-split-appear="fade-up" data-split-delay="100" data-split-by="line">
                  Échangeons autour de votre innovation.
                </div>
                <div className="bringer-cta-text">
                  <div className="stg-row stg-valign-middle">
                    <div className="stg-col-2 stg-offset-2 stg-tp-col-1 stg-tp-offset-3 stg-m-col-1 stg-m-offset-2" data-appear="fade-right">
                      <i className="bringer-cta-icon"></i>
                    </div>
                    <div className="stg-col-8 stg-tp-col-7 stg-m-col-8 stg-m-offset-1" data-appear="fade-left">
                      <p className="bringer-large-text">Nous aidons les entreprises innovantes à structurer leur développement et à lancer de nouveaux produits.</p>
                    </div>
                  </div>
                </div>
                <div className="bringer-cta-counters bringer-grid-3cols bringer-m-grid-3cols" data-stagger-appear="fade-up" data-stagger-delay="100">
                  <div className="bringer-counter bringer-small-counter" data-delay="3000">
                    <div className="bringer-counter-number" data-suffix="+">20</div>
                    <div className="bringer-counter-label">Années d'Expérience</div>
                  </div>{/* .bringer-counter */}
                  {/* Counter Item */}
                  <div className="bringer-counter bringer-small-counter" data-delay="3000">
                    <div className="bringer-counter-number" data-suffix="+">200</div>
                    <div className="bringer-counter-label">Projets IT réalisées</div>
                  </div>{/* .bringer-counter */}
                  {/* Counter Item */}
                  <div className="bringer-counter bringer-small-counter" data-delay="3000">
                    <div className="bringer-counter-number" data-suffix="+">10</div>
                    <div className="bringer-counter-label">Entreprises créées</div>
                  </div>{/* .bringer-counter */}
                  {/* Counter Item */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>

  )
}

export default Contact