import { React, useState } from 'react'
import { useLocation } from 'react-router-dom';
import MetaTags from '../components/MetaTags';

function Startup() {

    const [fullname, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [response, setResponse] = useState("");
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let origin = location.pathname.length > 1 ? location.pathname.replace('/', '').charAt(0).toUpperCase() + location.pathname.slice(2) : 'Home';

            let res = await fetch("https://hook.eu2.make.com/iauzb7zypebiqon5rdbslwtnmglbbgkf", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    fullname: fullname,
                    phoneNumber: phoneNumber,
                    origine: origin
                }),
            });
            const resJson = await res;
            if (res.status === 200) {
                setFullName("");
                setPhoneNumber("");
                setResponse("Nous vous contactons rapidement.");
            } else {
                setResponse("Oups ! Une erreur s'est produite.");
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <MetaTags
                title='Vous êtes une Startup'
                description='Ensemble, prouvons la valeur de votre innovation technologique et inspirons la confiance.'
                image='assets/img/inner-pages/pitch.jpg'
                name='Jelam'
            />
            <div className='stg-container'>
                {/* Hero Section */}
                <section>
                    <div className="bringer-hero-block bringer-hero-type01">
                        {/* Main Line */}
                        <div className="stg-row stg-large-gap stg-valign-middle stg-tp-column-reverse">
                            <div className="stg-col-6 stg-tp-bottom-gap-l stg-m-bottom-gap" data-unload="fade-right" data-appear="fade-left">
                                <div className="bringer-parallax-media">
                                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/pitch.jpg" alt="Pitch Startup" width="360" height="640" />
                                </div>
                            </div>
                            <div className="stg-col-9" data-appear="fade-right" data-unload="fade-left">
                                {/* Title */}
                                <h1 className="bringer-page-title" data-split-appear="fade-up" data-split-unload="fade-up">Structurons votre projet</h1>
                                <div className="stg-top-gap">
                                    <p className="bringer-large-text">Pour que votre <b className="jelam-special-word">STARTUP</b> inspire la confiance</p>
                                    <p>La confiance bâtit chaque projet innovant. Elle naît de votre conviction, se renforce par une structure solide, puis rayonne vers vos partenaires, clients et investisseurs.</p>
                                    <p>Chez Jelam, nous comprenons les défis que vous rencontrez. Nous transformons cette confiance en force concrète, posant les bases pour que votre projet inspire et fédère tout son écosystème.</p>
                                    <p>Ensemble, faisons prospérer votre idée grâce à une confiance partagée et durable.</p>
                                    {/* <p>La confiance est le socle de tout projet innovant. Elle commence par une conviction en soi et en son idée, se renforce grâce à une structure solide, puis se propage naturellement à vos partenaires, clients et investisseurs. Chez Jelam, nous croyons que pour qu’une idée atteigne son plein potentiel, elle doit être portée par une équipe convaincue de sa valeur, et soutenue par un écosystème qui partage cette vision.</p>
                                <p>En apportant une structure rigoureuse à votre projet, nous transformons cette confiance en une force tangible. Ensemble, nous posons les bases nécessaires pour que votre projet inspire non seulement votre équipe, mais aussi tous ceux qui participent à votre aventure entrepreneuriale. Parce qu'une idée brillante ne peut prospérer que lorsqu'elle est soutenue par une confiance partagée et durable.</p> */}
                                </div>
                            </div>
                        </div>{/* .stg-row */}
                    </div>{/* .bringer-hero-block */}
                </section>

                {/* Section: Steps */}
                <section className="backlight-top">
                    <div className="stg-bottom-gap-l">
                        <h2 data-appear="fade-up" data-unload="fade-up">Où en êtes-vous Aujourd'hui ?</h2>
                    </div>
                    {/* Step 01 Row */}
                    <div className="stg-row stg-bottom-gap stg-valign-middle stg-m-bottom-gap-l" data-unload="fade-left">
                        <div className="stg-col-3 stg-offset-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-right">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/bonne-idee.jpg" alt="Bonne idée" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-col-6" data-appear="fade-right" data-delay="100">
                            <p className='bringer-large-text'>Vous vous demandez si votre idée est bonne ?</p>
                            <p>Il est normal de douter, de ressentir que votre idée reste abstraite, voire infranchissable, tant les tâches à accomplir sont nombreuses.</p>
                        </div>
                    </div>
                    {/* Step 02 Row */}
                    <div className="stg-row stg-bottom-gap stg-valign-middle stg-tp-row-reverse stg-m-bottom-gap-l" data-unload="fade-right">
                        <div className="stg-col-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-left">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/pionnier.jpg" alt="Être le premier" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-col-6" data-appear="fade-left" data-delay="100">
                            <p className='bringer-large-text'>Vous voulez être un pionnier ?</p>
                            <p>Il est parfois difficile de rassurer les investisseurs sur le potentiel de vos recherches à moyen terme. Avec notre expérience des technologies comme l'IA et la Blockchain, nous comprenons bien les défis que cela pose en termes de temps et de ressources.</p>
                        </div>
                        <div className="stg-col-3">{/* Empty Column */}</div>
                    </div>
                    {/* Step 03 Row */}
                    <div className="stg-row stg-bottom-gap stg-valign-middle stg-m-bottom-gap-l" data-unload="fade-left">
                        <div className="stg-col-3 stg-offset-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-right">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/soutien.jpg" alt="Du soutien" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-col-6" data-appear="fade-right" data-delay="100">
                            <p className='bringer-large-text'>Vous aimeriez être soutenu ?</p>
                            <p>Une grande idée exige une bonne équipe. Sans le réseau adéquat, il est difficile de savoir à qui faire confiance pour vous aider à concrétiser votre projet.</p>
                        </div>
                    </div>
                    {/* Step 04 Row */}
                    <div className="stg-row stg-valign-middle stg-tp-row-reverse" data-unload="fade-right">
                        <div className="stg-col-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-left">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/change-world.jpg" alt="Changer le monde" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-col-6" data-appear="fade-left" data-delay="100">
                            <p className='bringer-large-text'>Vous voulez aider à changer le monde ?</p>
                            <p>Nous comprenons qu'il est difficile de savoir par où commencer lorsque vous êtes seul(e) et que vous manquez d'expérience ou de compétences techniques.</p>
                        </div>
                        <div className="stg-col-3">{/* Empty Column */}</div>
                    </div>
                </section>

                {/* Section: Services */}
                <section className="backlight-bottom">
                    {/* Section Title */}
                    <div className="stg-row bringer-section-title">
                        <div className="stg-col-8 stg-offset-2">
                            <div className="align-center">
                                <h2 data-appear="fade-up" data-unload="fade-up">Ensemble, nous surmontons les obstacles</h2>
                            </div>
                        </div>
                    </div>
                    {/* List with Preview */}
                    <div className="bringer-list-with-preview" data-preview-position="left" data-unload="fade-up" data-delay="200">
                        <div className="bringer-lwp-roster">
                            {/* Item 01 */}
                            <div className="bringer-lwp-item bringer-block" data-appear="fade-left">
                                <img src="assets/img/inner-pages/affiner.jpg" alt="Brainstorming" width="800" height="1200" />
                                <div className="bringer-lwp-item-content">
                                    <h5>Affinons votre idée et validons son intérêt<span className="bringer-accent">.</span></h5>
                                    <ul>
                                        <li>
                                            Nous vous guidons dans un processus d’idéation pour explorer plus en profondeur votre idée.
                                        </li>
                                        <li>
                                            Nous identifions, interrogeons et segmentons vos cibles potentielles.
                                        </li>
                                        <li>
                                            Nous réalisons un prototype et testons les réactions des utilisateurs.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* Item 02 */}
                            <div className="bringer-lwp-item bringer-block" data-appear="fade-left">
                                <img src="assets/img/inner-pages/choisir.jpg" alt="Décision" width="800" height="1200" />
                                <div className="bringer-lwp-item-content">
                                    <h5>Allons au bout de vos rêves tout en structurant la vision<span className="bringer-accent">.</span></h5>
                                    <ul>
                                        <li>
                                            Nous recherchons les usages innovants potentiels de votre technologie.
                                        </li>
                                        <li>
                                            Nous identifions les bénéfices pour les parties prenantes.
                                        </li>
                                        <li>
                                            Nous planifions et rendons agile la réalisation.
                                        </li>
                                        <li>
                                            Nous vous aidons à faire des choix structurants.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* Item 03 */}
                            <div className="bringer-lwp-item bringer-block" data-appear="fade-left">
                                <img src="assets/img/inner-pages/tester.jpg" alt="Market Testing" width="800" height="1200" />
                                <div className="bringer-lwp-item-content">
                                    <h5>Soyez pertinent et touchons rapidement votre cible<span className="bringer-accent">.</span></h5>
                                    <ul>
                                        <li>
                                            Nous pratiquons une analyse ciblée du marché et de ses contraintes.
                                        </li>
                                        <li>
                                            Nous identifions et privilégions les fonctionnalités à forte valeur ajoutée.
                                        </li>
                                        <li>
                                            Nous structurons un plan de développement.
                                        </li>
                                        <li>
                                            Nous planifions le déploiement en fonction de la cible.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* Item 04 */}
                            <div className="bringer-lwp-item bringer-block" data-appear="fade-left">
                                <img src="assets/img/inner-pages/success.jpg" alt="Succes" width="800" height="1200" />
                                <div className="bringer-lwp-item-content">
                                    <h5>Réduisons les risques et inspirez la confiance<span className="bringer-accent">.</span></h5>
                                    <ul>
                                        <li>
                                            Nous cherchons l’équilibre entre les coûts, les risques et le potentiel.
                                        </li>
                                        <li>
                                            Nous mesurons les résultats en temps réel et adaptons la stratégie.
                                        </li>
                                        <li>
                                            Nous éditons un plan de lancement ambitieux et réalisable.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* Learn More */}
                            <div className="align-right">
                                <a href="/productstudio" className="bringer-arrow-link">Rejoignez le Product Studio</a>
                            </div>
                        </div>
                    </div>{/* .bringer-list-with-preview */}
                </section>


                {/* Section: What Makes Us Best */}
                <section className="backlight-bottom">
                    {/* Section Title */}
                    <div className="stg-row bringer-section-title">
                        <div className="stg-col-8 stg-offset-2">
                            <div className="align-center">
                                <h2 data-appear="fade-up" data-unload="fade-up">Nous sommes des chefs d'entreprises</h2>
                                <p data-appear="fade-up" data-unload="fade-up" data-delay="100">Grâce à notre solide expérience technique, nous comprenons parfaitement vos besoins. En plus, vous profitez de notre expertise en création d'entreprises, de notre accompagnement de nombreuses startups, et de notre gestion de centaines de projets numériques.</p>
                            </div>
                        </div>
                    </div>
                    {/* Content Grid */}
                    <div className="bringer-grid-2cols bringer-tp-grid-1col" data-stagger-appear="zoom-in" data-stagger-delay="100" data-stagger-unload="fade-up">
                        {/* Card Item 01 */}
                        <div className="bringer-block">
                            <h4>Entrepreneuriat</h4>
                            <h6><span className="bringer-accent">Nous comprenons vos défis en tant qu'entrepreneur.</span></h6>
                            <p>Notre expertise vous accompagne pour transformer vos idées en entreprises viables, en vous aidant à surmonter les obstacles avec une approche structurée qui maximise vos chances de succès.</p>
                        </div>{/* .bringer-block */}

                        {/* Card Item 02 */}
                        <div className="bringer-block">
                            <h4>Stratégie produit</h4>
                            <h6><span className="bringer-accent">Concevons des produits qui font la différence.</span></h6>
                            <p>Grâce à notre maîtrise de la stratégie produit, nous vous aidons à affiner vos idées et à les positionner efficacement sur le marché pour répondre aux attentes de vos clients.</p>
                        </div>{/* .bringer-block */}

                        {/* Card Item 03 */}
                        <div className="bringer-block">
                            <h4>Gestion de projet agile</h4>
                            <h6><span className="bringer-accent">Limitons les surcoûts de développement.</span></h6>
                            <p>Nous vous aidons à organiser vos réalisations pour livrer des produits de qualité, tout en minimisant les risques et en respectant les délais.</p>
                        </div>{/* .bringer-block */}

                        {/* Card Item 04 */}
                        <div className="bringer-block">
                            <h4>Innovation technologique</h4>
                            <h6><span className="bringer-accent">Offrons au monde votre technologie.</span></h6>
                            <p>Nous vous aidons à intégrer et à vulgariser les technologies émergentes pour créer des solutions qui recontrent les besoins de vos clients et renforcent votre leadership.</p>
                        </div>{/* .bringer-block */}
                    </div>
                </section>

                {/* Section: Marquee */}
                <section className="is-fullwidth is-stretched" data-padding="none">
                    <div className="align-center stg-bottom-gap-l">
                        <h2 data-appear="fade-up" data-unload="fade-up">Et voici ce que nous et nos partenaires maîtrisons</h2>
                    </div>
                    <div className="bringer-marquee stg-bottom-gap-l" data-speed="5000">
                        <div className="bringer-marquee-inner">
                            <ul className="bringer-marquee-list">
                                <li>
                                    <h2>CTO</h2>
                                </li>
                                <li>
                                    <h2>Support DAF</h2>
                                </li>
                                <li>
                                    <h2>UX/UI Design</h2>
                                </li>
                                <li>
                                    <h2>Design Thinking</h2>
                                </li>
                                <li>
                                    <h2>Computer Vision, IOT</h2>
                                </li>
                                {/* <li>
                                    <h2>Propriété intellectuelle, brevet</h2>
                                </li> */}
                            </ul>
                        </div>{/* .bringer-marquee-inner */}
                    </div>{/* .bringer-marquee */}
                </section>

                {/* Section: CTA Form */}
                <section data-padding="bottom">
                    <div className="bringer-masked-cta bringer-masked-block" data-unload="fade-down">
                        <form onSubmit={handleSubmit} method="post" data-fill-error="Veuillez compléter les champs obligatoires." className="bringer-contact-form is-short bringer-masked-media" data-appear="fade-up">
                            <div className="bringer-form-content bringer-cta-form">
                                <div className="bringer-cta-form-content" data-appear="fade-up" data-delay="100">
                                    <div className="bringer-cta-title">Prouvons le potentiel de votre idée.</div>
                                    <input type="text" id="fullname" name="fullname" placeholder="Votre nom" required value={fullname} onChange={(e) => setFullName(e.target.value)} />
                                    <input type="tel" pattern="^(\+33|0)[1-9](\d{2}){4}$" id="phoneNumber" name="phoneNumber" placeholder="Votre numéro" required value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                </div>
                                <div className="bringer-cta-form-button" data-appear="fade-up" data-delay="200">
                                    <button type="submit">
                                        <span className="bringer-icon bringer-icon-arrow-submit"></span>
                                    </button>
                                </div>
                                <div className="bringer-contact-form__response">{response}</div>
                            </div>
                            <span className="bringer-form-spinner"></span>
                        </form>
                        <div className="bringer-masked-cta-content bringer-masked-content at-top-right">
                            <p className="bringer-large-text" data-appear="fade-down">Échangeons sur votre projet. ✨</p>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Startup