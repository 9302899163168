import React from 'react'

function Escapad() {
    return (
        <div className='stg-container'>
            {/* Section: Page Title */}
            <section className="backlight-bottom">
                <div className="stg-row stg-bottom-gap-l">
                    <div className="stg-col-8 stg-tp-bottom-gap" data-appear="fade-right" data-unload="fade-left">
                        <h1>Globe Climber - Escape Game </h1>
                        <h2>Le Bloc Ultime</h2>
                    </div>
                    <div className="stg-col-4 stg-tp-col-8 stg-tp-offset-4 tp-align-right stg-m-col-9 stg-m-offset-3" data-appear="fade-left" data-unload="fade-right">
                        <p>Le Bloc Ultime est un Escape Game en plein air, réalisable en toute autonomie, dans la fôret de Fontainebleau.</p>
                    </div>
                </div>

                {/* Slider */}
                <div className="bringer-slider-wrapper bringer-masked-block stg-bottom-gap" data-appear="fade-up" data-delay="100" data-unload="fade-up">
                    <div className="swiper bringer-slider bringer-masked-media" data-autoplay="2000" data-duration="800" data-effect="slide">
                        <div className="swiper-wrapper">

                            {/* Slider Item */}
                            <div className="swiper-slide">
                                <img src="assets/img/portfolio/escape_game.jpg" alt="Escape game" width="1920" height="1080" />
                            </div>

                            {/* Slider Item */}
                            <div className="swiper-slide">
                                <img src="assets/img/portfolio/Fontainebleau1.jpg" alt="Forêt de Fontainebleau" width="1920" height="1080" />
                            </div>
                        </div>
                    </div>{/* .bringer-slider */}
                    {/* Masked Navigation */}
                    <div className="bringer-slider-nav bringer-masked-content at-bottom-right">
                        <a href="/#" className="bringer-slider-prev">
                            <span className="bringer-icon bringer-icon-chevron-left"></span>
                        </a>
                        <a href="/#" className="bringer-slider-next">
                            <span className="bringer-icon bringer-icon-chevron-right"></span>
                        </a>
                    </div>
                </div>

                {/* Meta */}
                <div className="bringer-hero-info-line" data-stagger-appear="fade-up" data-delay="200" data-unload="fade-up">
                    <div className="bringer-meta">
                        Client: <span>Globe Climber</span>
                    </div>
                    <div className="bringer-meta">
                        Studio: <span>Product Studio</span>
                    </div>
                </div>{/* .bringer-hero-info-line */}
            </section>

            {/* Section: The Challenge */}
            <section>
                <div className="stg-row">
                    <div className="stg-col-6 stg-tp-bottom-gap-l" data-unload="fade-left">
                        <div className="bringer-sticky-block">
                            <h2 data-appear="fade-right">À propos du projet</h2>
                        </div>
                    </div>
                    <div className="stg-col-6 stg-tp-col-10 stg-tp-offset-2 stg-m-col-11 stg-m-offset-1" data-unload="fade-right">
                        <div className="stg-bottom-gap-section stg-tp-bottom-gap-l" data-appear="fade-up">
                            <h3>Le Challenge</h3>
                            <p>Aider Globe Climber a diversifié son activité grâce au numérique.</p>
                            <ul className="bringer-marked-list">
                                <li>Réaliser notre premier Escape Game.</li>
                                <li>Innover autour d’un concept déjà connu.</li>
                                <li>Créer une expérience utilisateur immersive.</li>
                            </ul>
                        </div>
                        <div className="stg-bottom-gap-section stg-tp-bottom-gap-l" data-appear="fade-up">
                            <h3>Les objectifs</h3>
                            <ul className="bringer-marked-list">
                                <li>Concevoir un produit évolutif combinant aventure, éducation et divertissement.</li>
                                <li>Proposer une histoire captivante et immersive.</li>
                                <li>Faire découvrir l’histoire de l’escalade et la richesse de Fontainebleau.</li>
                                <li>Assurer le fonctionnement sans connexion internet.</li>
                            </ul>
                        </div>
                        <div data-appear="fade-up">
                            <h3>Notre approche</h3>
                            <h6>Structure et méthode</h6>
                            <div>
                                <ul className="bringer-marked-list">
                                    <li><span className="bringer-accent">Brainstorming</span> pour challenger le statut quo.</li>
                                    <li><span className="bringer-accent">UX Design</span> pour s’assurer de l’adoption des utilisateurs finaux.</li>
                                    <li><span className="bringer-accent">Narrative Design</span> pour créer une histoire immersive.</li>
                                    <li><span className="bringer-accent">Scrum</span> pour une gestion agile du projet.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Section: Gallery */}
            <section data-tp-padding="none">
                <div className="bringer-grid-3cols" data-stagger-appear="fade-up" data-stagger-unload="fade-left">
                    <div>
                        <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/portfolio/mockup_affiche.jpg" alt="affiche escape game" data-speed="0.9" data-m-speed="1" width="800" height="1200" />
                    </div>
                    <div>
                        <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/portfolio/mockup_description.jpg" alt="liste d'énigme" width="800" height="1200" />
                    </div>
                    <div>
                        <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/portfolio/mockup_question_énigme.jpg" alt="écran énigme" data-speed="1.1" data-m-speed="1" width="800" height="1200" />
                    </div>
                </div>
            </section>
            {/* Section: Solution */}
            <section>
                <div className="stg-row">
                    <div className="stg-col-6 stg-tp-bottom-gap-l" data-appear="fade-right" data-unload="fade-left">
                        <h2>Ce que nous avons réalisé</h2>
                    </div>
                    <div className="stg-col-6 stg-tp-col-10 stg-tp-offset-2 stg-m-col-11 stg-m-offset-1" data-appear="fade-left" data-unload="fade-right">
                        <p className="bringer-large-text">Ensemble, nous avons transformé une idée en réalité. Nous avons :</p>
                        <ul className="bringer-marked-list">
                            <li>Conçu une histoire passionnante.</li>
                            <li>Identifié des fonctionnalités innovantes.</li>
                            <li>Mené des interviews et des tests en conditions réelles.</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section data-padding="none" data-unload="zoom-out">
                <div className="bringer-expand-on-scroll">
                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/portfolio/péle-méle.jpg" alt="ALTTODO" width="1920" height="960" />
                </div>{/* .bringer-expand-on-scroll */}
            </section>

            {/* Section: Results & Impact */}
            <section>
                <div className="stg-row">
                    <div className="stg-col-6 stg-tp-bottom-gap" data-appear="fade-up" data-unload="fade-left">
                        <div className="bringer-sticky-block">
                            <h2>Résultats et succès</h2>
                        </div>
                    </div>
                    <div className="stg-col-6 stg-tp-col-10 stg-tp-offset-2 stg-m-col-11 stg-m-offset-1" data-appear="fade-up" data-delay="100" data-unload="fade-right">
                        <ul className="bringer-marked-list">
                            <li><span className="bringer-highlight">Des testeurs satisfaits</span> qui attendent la lancement de l'aventure.</li>
                            <li><span className="bringer-highlight">Un nouveau service</span> à venir qui permettra à Globe Climber de fidéliser une nouvelle clientèle.</li>
                            <li><span className="bringer-highlight">Des perspectives d’évolution</span> pour asseoir la position de Globe Climber dans le secteur du loisir.</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Section: The Challenge */}
            <section>
                <div className="stg-row">
                    <div className="stg-col-6 stg-tp-bottom-gap-l" data-unload="fade-left">
                        <div className="bringer-sticky-block">
                            <h2 data-appear="fade-right">Ça s'est réellement produit </h2>
                        </div>
                    </div>
                    <div className="stg-col-6 stg-tp-col-10 stg-tp-offset-2 stg-m-col-11 stg-m-offset-1" data-unload="fade-right">
                        <div className="stg-bottom-gap-section stg-tp-bottom-gap-l" data-appear="fade-up">
                            <h3>Prendre le temps d'explorer</h3>
                            <p>Nous première action était loin de la technique. Pour découvrir les offres du marché, nous avons participé à un Escape Game en plein air.</p>
                            <p>Puis étant novice dans le domaine de l’escalade, nous nous sommes immergés dans ce monde, profitant des journées d’été pour découvrir le site exceptionnel de la forêt de Fontainebleau.</p>
                        </div>
                        <div data-appear="fade-up">
                            <h3>Nos valeurs comme atout</h3>
                            <p>Passionnés d’Escape Game, nous savions que le succès de cette aventure et l’attrait du produit résidaient dans la conception d’une histoire cohérente et immersive, ainsi que dans la création d’énigmes ludiques.</p>
                            <p>C’est alors qu’Arthur a rejoint notre équipe. Grâce à nos valeurs, il s’est rapidement intégré et, avec son talent et sa créativité, il a su imaginer un contexte de jeu exceptionnel.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Section: Testimonials */}
            <section>
                {/* Section Title */}
                <div className="stg-row bringer-section-title">
                    <div className="stg-col-8 stg-offset-2">
                        <div className="align-center">
                            <h2 data-appear="fade-up" data-unload="fade-up">Témoignages inspirants</h2>
                        </div>
                    </div>
                </div>
                {/* Grid Testimonials */}
                <div className="bringer-grid-2cols" data-stagger-appear="fade-up" data-stagger-unload="fade-up">
                    {/* Card Item 01 */}
                    {/* <div className="bringer-block bringer-tetimonials-card">
                        <div className="bringer-tetimonials-card-descr">
                            "Aptent ad urna ipsum est etiam! Lacinia vivamus risus pulvinar luctus; metus est curae pretium. Commodo ut dictumst montes velit aenean nullam. Ex turpis pellentesque posuere molestie ridiculus donec. Tincidunt varius augue lacinia egestas sagittis diam nec leo. Nunc eget per scelerisque purus platea lacinia dis integer. Dui facilisi urna per; morbi magna finibus. Iaculis bibendum gravida vulputate egestas diam tincidunt."
                        </div>
                        <div className="bringer-tetimonials-card-footer">
                            <div className="bringer-tetimonials-card-name">
                                <h6>Jérôme</h6>
                                <span className="bringer-meta">Président de Globe Climber</span>
                            </div>
                            <div className="bringer-tetimonials-card-rate">
                                <span className="bringer-tetimonials-stars5"></span>
                            </div>
                        </div>
                    </div> */}
                    {/* .bringer-tetimonials-card */}
                    {/* Card Item 02 */}
                    <div className="bringer-block bringer-tetimonials-card">
                        <div className="bringer-tetimonials-card-descr">
                            "Ce fut une aventure passionnante qui nous a permis de découvrir l'histoire de l'escalade et un site exceptionnel. Nous avons fait preuve de pragmatisme et d'agilité pour aligner le produit aux contraintes et aux attentes des utilisateurs et de Jérôme."
                        </div>
                        <div className="bringer-tetimonials-card-footer">
                            <div className="bringer-tetimonials-card-name">
                                <h6>Jean-Noël</h6>
                                <span className="bringer-meta">CEO de JELAM</span>
                            </div>
                            <div className="bringer-tetimonials-card-rate">
                                <span className="bringer-tetimonials-stars5"></span>
                            </div>
                        </div>
                    </div>{/* .bringer-tetimonials-card */}
                    {/* Card Item 02 */}
                    <div className="bringer-block bringer-tetimonials-card">
                        <div className="bringer-tetimonials-card-descr">
                            "J'ai adoré tester cette aventure en pleine forêt de Fontainebleau. Le cadre est magnifique, l'histoire captivante et l'immersion totale. C'est une belle découverte de la forêt et de l'histoire de l'escalade, accessible à tous. Une expérience unique à vivre !"
                        </div>
                        <div className="bringer-tetimonials-card-footer">
                            <div className="bringer-tetimonials-card-name">
                                <h6>Estelle</h6>
                                <span className="bringer-meta">Testeuse</span>
                            </div>
                            <div className="bringer-tetimonials-card-rate">
                                <span className="bringer-tetimonials-stars5"></span>
                            </div>
                        </div>
                    </div>{/* .bringer-tetimonials-card */}
                </div>
            </section>
        </div>
    )
}

export default Escapad